import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/mdxLayouts/PostsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PWAs are becoming a more popular way of creating quick and multiplatform applications. If you are making a PWA and can't seem to get the top bar out of your way you just need to change a single word. Display is a property that is in your manifest file and takes a range of options that can greatly affect the flow of your app.`}</p>
    <h2>{`Display properties`}</h2>
    <p>{`The display property is what controls the view of your PWA. It can make your application fullscreen or fill the full viewport with your window. The latter is used most to emulate the feel of a native app. Below I have a quick breakdown of each property and if I missed something feel free to let me know.`}</p>
    <h2>{`Minimal-UI`}</h2>
    <p>{`Minimal UI is the default display value in the offline plugin that comes with Gatsby and leads to a less than ideal PWA. This property will keep the top navigation bar and limits the UX of your app by cropping the screen with an address bar.`}</p>
    <h2>{`Standalone`}</h2>
    <p>{`This is what most PWAs will use to get as close to the native experience as possible. Standalone doesn't fully cover the user's screen and just fills the viewport that would be available to other types of applications.`}</p>
    <h2>{`Fullscreen`}</h2>
    <p>{`Fullscreen will cover your user's entire screen. On Android, this includes the status bar. You will want to be sure you won't limit UX by locking your user into your app. If you are making a to-do list there is no reason to take up the entirety of your user's screen and will likely lead to more frustration.`}</p>
    <h2>{`Browser`}</h2>
    <p>{`This is likely the option you will never pick. When opening your app it will launch straight into a browser with no UI limiting. This can be good but most of the time it will be more a hindrance than a benefit.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Making sure that your PWA is functioning and feeling the way it should be difficult but changing a single property can really make a difference. I hope you found this quickie useful if you have anything to add feel free to send me a message.`}</p>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      